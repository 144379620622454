import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Paragraph } from '../Paragraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "paragraph"
    }}>{`Paragraph`}</h1>
    <p>{`The Paragraph is used for body copy. It comes with default margins and is optimised for readability.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Paragraph} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`The main props to set are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`marginBottom`}</strong>{` - set to 0, if you don't want the default margin.`}</li>
      <li parentName="ul"><strong parentName="li">{`color`}</strong>{` - is set to `}<inlineCode parentName="li">{`inherit`}</inlineCode>{` to adapt to the context, but you might want to explicitly set the color if needed.`}</li>
    </ul>
    <p>{`If you need more options, consider using `}<a parentName="p" {...{
        "href": "components/Text"
      }}>{`Text`}</a>{` or `}<a parentName="p" {...{
        "href": "components/Heading"
      }}>{`Heading`}</a>{` instead.`}</p>
    <Playground __position={1} __code={'<Paragraph>\n  Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware\n  williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom\n  locavore, kogi everyday carry af tattooed art party asymmetrical cardigan\n  sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen\n  brooklyn bushwick distillery. Vape stumptown swag glossier small batch\n  gastropub. Taxidermy 90\'s everyday carry kombucha. Banjo VHS occupy marfa\n  roof party slow-carb.\n</Paragraph>\n<Paragraph>\n  Pour-over ethical echo park pork belly kickstarter tumeric cornhole\n  humblebrag live-edge hammock. Farm-to-table irony ennui brunch keytar\n  knausgaard tofu. Hashtag sartorial organic franzen. Taxidermy woke 90\'s\n  crucifix prism offal. Vape microdosing messenger bag whatever bitters banh\n  mi intelligentsia art party chambray lyft.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware
    williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom
    locavore, kogi everyday carry af tattooed art party asymmetrical cardigan
    sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen
    brooklyn bushwick distillery. Vape stumptown swag glossier small batch
    gastropub. Taxidermy 90's everyday carry kombucha. Banjo VHS occupy marfa
    roof party slow-carb.
  </Paragraph>
  <Paragraph mdxType="Paragraph">
    Pour-over ethical echo park pork belly kickstarter tumeric cornhole
    humblebrag live-edge hammock. Farm-to-table irony ennui brunch keytar
    knausgaard tofu. Hashtag sartorial organic franzen. Taxidermy woke 90's
    crucifix prism offal. Vape microdosing messenger bag whatever bitters banh
    mi intelligentsia art party chambray lyft.
  </Paragraph>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      